<template>
  <Anonymous>
    <b-overlay :show="showOverlay" rounded="sm">
      <div class="alert alert-danger" role="alert" v-if="fatalError">
        <div v-for="error in errors" :key="error.code">
          {{error.message}}
        </div>
      </div>
      <div class="form" v-if="!fatalError">
        <div class="row">
          <div class="col-sm-12">
            <div class="title">Account creation</div>
          </div>
          <div class="col-sm-12">
            <b-form @submit="onSubmit" class="mt-4">
              <div role="group" class="form-group-input">
                <label for="account-creation-email">Email address</label>
                <b-form-input
                    id="account-creation-email"
                    v-model="email"
                    :disabled="true"
                    autocomplete="username"
                    type="text"
                ></b-form-input>

              </div>
              <div role="group" class="form-group-input mt-4">
                <label for="account-creation-full-name">Full name</label>
                <b-form-input
                    id="account-creation-full-name"
                    v-model="name"
                    :disabled="true"
                    type="text"
                ></b-form-input>
              </div>
              <div role="group" class="form-group-input mt-4">
                <label for="account-creation-password">Password</label>
                <b-form-input
                    id="account-creation-password"
                    v-model="form.password"
                    autocomplete="current-password"
                    :type=inputPasswordType
                    :state="validateState('password')"
                ></b-form-input>

                <b-form-invalid-feedback id="input-email-feedback">
                  <div v-if="!$v.form.password.required">The password is required</div>
                  <div v-else-if="!$v.form.password.minLength">Must not be at less 8 and more 255 characters</div>
                  <div v-else>
                    This password is not correct
                  </div>
                </b-form-invalid-feedback>

                <div class="icon">
                  <b-icon icon="eye" font-scale="1.5" @click="togglePassword"></b-icon>
                </div>
              </div>
              <div role="group" class="form-group-input mt-4">
                <label for="confirm-password">Confirm password</label>
                <b-form-input
                    id="confirm-password"
                    v-model="form.confirmPassword"
                    :type=inputConfirmPasswordType
                    :state="validateState('confirmPassword')"
                  ></b-form-input>
                <div class="icon">
                  <b-icon icon="eye" font-scale="1.5" @click="toggleConfirmPassword"></b-icon>
                </div>
                <b-form-invalid-feedback>
                  <div v-if="!$v.form.confirmPassword.sameAsPassword">Password must be identical</div>
                </b-form-invalid-feedback>
              </div>
              <div role="group" class="form-group-input mt-4">

                <b-form-checkbox id="account-creation-accept"
                                 v-model="form.accept"
                                 :state="validateState('accept')">
                  Agree with <a href="https://forrestbrown.co.uk/myfb-terms-of-use/" target="_blank">terms and conditions</a>
                </b-form-checkbox>
                <b-form-invalid-feedback :force-show="$v.form.accept.$anyError">
                  <div v-if="!$v.form.accept.sameAs">You should accept terms and conditions</div>
                </b-form-invalid-feedback>
              </div>
              <div class="mt-4 d-flex">
                <div class="flex-fill">
                  <b-button type="submit" variant="primary">Create account</b-button>
                </div>
              </div>
            </b-form>
          </div>

        </div>
      </div>
    </b-overlay>

  </Anonymous>
</template>

<script>

import _ from "lodash";
import Anonymous from "../layouts/Anonymous";
import {validationMixin} from 'vuelidate';
import {required, maxLength, minLength, sameAs} from "vuelidate/lib/validators";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AccountCreation",
  components: {Anonymous},
  mixins: [validationMixin],
  data() {
    return {
      form: {
        password: '',
        passwordConfirm: '',
        accept: false,
      },
      showPassword: false,
      showConfirmPassword: false,
      showOverlay: true
    }
  },
  validations: {
    form: {
      password: {
        required,
        maxLength: maxLength(255),
        minLength: minLength(8)
      },
      confirmPassword: {
        sameAsPassword: sameAs('password')
      },
      accept: {
        sameAs: sameAs( () => true )
      }
    }
  },
  mounted() {
      this.clear();
    this.fetchInvitation(this.$route.params.token).then(() => {
      this.showOverlay = false;
      if (this.invitation.action === 'GO_TO_ORGANIZATION') {
        this.$router.push('/login').catch(() => {});
      }
    });
  },
  computed: {
    ...mapGetters("invitation", ["invitation"]),
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapGetters("errors", ['hasErrors', 'errors']),
    inputPasswordType: function () {
      if (this.showPassword) {
        return 'text';
      }
      return 'password';
    },
    inputConfirmPasswordType() {
      return this.showConfirmPassword ? 'text' : 'password';
    },
    author: function () {
      return this.invitation.author_firstname + ' ' + this.invitation.author_lastname;
    },
    email: function () {
      return this.invitation.email;
    },
    name: function () {
      return this. invitation.firstname + ' ' + this.invitation.lastname;
    },
    fatalError: function () {
      return this.errors.some(function (error) {
        if (_.isEmpty(error.errors)) {
          return false
        }else {
          if (Object.keys(error.errors).includes('inviteId')) {
            return true;
          }else {
            return false;
          }
        }
      })

    }
  },

  methods: {
    ...mapActions("invitation", ["fetchInvitation", "activateInvitation"]),
    ...mapActions("auth", ["login", "clear"]),
    onSubmit(event) {

      event.preventDefault()
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.showOverlay = true;
      this.activateInvitation({
        password: this.form.password,
        firstname: this.invitation.firstname,
        lastname: this.invitation.lastname,
        inviteId: this.invitation.inviteId
      }).then(() => {
        if (!this.hasErrors) {
          this.showOverlay = true;
          this.login({
            email: this.invitation.email,
            password: this.form.password
          }).then(() => {
            this.showOverlay = false;
          })
        }
        this.showOverlay = false;
      });
    },
    togglePassword() {
      if (this.showPassword) {
        this.showPassword = false;
      } else {
        this.showPassword = true;
      }
    },
    toggleConfirmPassword() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    validateState(name) {
      if (this.hasErrors) {
        return false;
      }
      const {$dirty, $error} = this.$v.form[name];
      return $dirty ? !$error : null;
    },
  }
}
</script>

<style scoped>

</style>